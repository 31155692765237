<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.editing }}</h4>
    <div class="admin-form-wrapper">
      <div v-if="lawItem" class="admin-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.heading }} ({{ $lang.app.ru }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleRu" type="text"
                     v-model="lawItem.titleRu"/>
            </div>
            <ErrorMessage name="titleRu" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.heading }} ({{ $lang.app.kg }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleKg" type="text"
                     v-model="lawItem.titleKg"/>
            </div>
            <ErrorMessage name="titleKg" class="recovery-label-error" />
          </div>
          <div class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.attach_file }}</label>
            <div v-if="lawItem.filename">
              <span class="filename">{{ lawItem.filename }}</span>
              <button class="remove-button" type="button" @click.prevent="removeFile(lawItem)"><i class="bi-x-square"></i></button>
            </div>
            <div v-else>
              <Field type="file" name="filename" accept="application/pdf"
                     @change="event => lawItem.fileDemo = event.target.files[0]"/>
              <ErrorMessage name="filename" class="recovery-label-error"/>
            </div>
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.update }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import fileUploadMixin from "../../../mixins/fileUploadMixin";
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  mixins: [fileUploadMixin],
  data() {
    return {
      lawItem: null,
    }
  },
  methods: {
    removeFile(lawItem) {
      lawItem.filename = null
    },
    async submit(values, actions) {

      if(!this.lawItem.filename && this.lawItem.fileDemo) {
        try {
          this.lawItem.filename = await this.uploadFile(this.lawItem.fileDemo)
        } catch (error) {
          if(error.response.status === 400 || error.response.status === 413) {
            actions.setErrors({
              filename: error.response.data.errors.file,
            });
          } else {
            this.$snotify.error(error)
          }
          return
        }
      }

      this.$axios.post(`/legal-act/edit/${this.$route.params.id}`, this.lawItem).then(() => {
        this.$snotify.success(this.$lang.app.resource_updated_successfully);
        this.$router.push('/admin/law')
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data);
        }
      })
    },
    fetchResource() {
      this.$axios.get(`/legal-act/${this.$route.params.id}`).then(({data}) => {
        this.lawItem = {
          ...data,
          fileDemo: null,
        }
      })
    },
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>